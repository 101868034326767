@media (min-width: 1360px) {
    .table-responsive {
        overflow-x: hidden;
    }
}
body.mobile #logo,
body.mobile #content {
    margin: 0;
}
body.mobile #content > .container-fluid {        
    padding-left: 0;
    padding-right: 0;
}
body.mobile .navbar-toggle {
    display: block;
}
body.mobile #sidebar-menu {
    display: none;
    width: 100%;
    display: block;
    position: relative;
}
body.mobile .navbar-collapse.collapse {
    display: none !important;
}
body.mobile .navbar-collapse.collapse.in { 
    display: block!important;
}
body.mobile .collapsing {
    overflow: hidden!important;
}
body.mobile #sidebar-menu .nav > li {
    float: left;
    width: 33.3%;
    position: relative;
}
body.mobile #sidebar-menu .nav > li > ul {
    display: none;
    position: absolute;
    z-index: 2;
    width: 100%;
    background: #0099CC;
}
body.mobile #sidebar-menu .nav > li.active {
    border: none;
}
body.mobile #sidebar-menu .nav > li.dropdown,
body.mobile #sidebar-menu .nav > li.active.open-toggle {
    padding-bottom: 0;
}
body.mobile .navbar-collapse.in {
    overflow: visible;
}

@media (max-width: 840px) {
    header {
        height: auto;
    }
    #logo {
        display: none;
    }
    #logo-mobile {
        display: block;
    }
    .navbar-toggle {
        width: 120px;
        height: 70px;
    }
}

@media (max-width: 768px) {
    body.mobile #sidebar-menu .nav > li {
        width: 50%;
    }
    .dl-horizontal.px240 dt {
        width: auto;
        margin-bottom: 0;
    }
    .dl-horizontal.px240 dd {
        margin-left: 0;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: #F5F5F5 1px solid;
    }
}
@media (max-width: 730px) {
    .nav-tabs > li {
        float:none;
        border:1px solid #ddd;
    }
}
@media (max-width: 550px) {
    .options-right {
        position: static;
        margin-bottom: 15px;
    }
}

@media (max-width: 440px) {
    footer .pull-right,
    footer .pull-left,
    header .pull-right {
        width: 100%;
        float: none !important;
        text-align: center;
    }
    body.mobile .navbar-toggle {
        display: none;
    }
    .navbar-toggle-440 {
        display: block !important;
        width: 100%;
        height: 50px;
        margin: 0;
    }
    body.mobile #sidebar-menu .nav > li {
        width: 100%;
    }
    body.mobile #sidebar-menu .nav > li > ul {
        position: relative;
    }
    body.mobile #sidebar-menu .nav a {
        font-size: 16px;
    }
    footer .pull-left {
        margin-bottom: 20px;
    }    
}