.campaign {
	&-title {
		max-width: 400px;
	}

	&-button {
		width: 130px;
		@media only screen and (min-width: 768px) {
			margin: 10px;
		}
		@media only screen and (max-width: 768px) {
			margin-bottom: 10px;
		}
	}
}

.text-code-col {
	margin-top: 18px;
}

.text-description-col {
	@media only screen and (min-width: 1762px) {
		margin-top: 18px;
	}
}

.text-number-col {
	@media only screen and (min-width: 1712px) {
		margin-top: 18px;
	}
}

#campaign-buttons-col {
	margin-top: 15px;
	padding-left: 0px;
}

#campaign-description-col {
	padding-right: 5px;
}

.from-shop-dialog{
	width: 1200px !important;
}

.from-shop-links{
	margin: 15px;
}