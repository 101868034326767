.pp {
  &-panel {
    padding: 1rem;
  }

  &-main-link {
    border: #5B9BD5 solid 2px;
    padding-top: 20px;
    padding-bottom: 20px
  }

  &-copy-row {
    margin-top: -27px;
  }

  &-copy-btn {
    width: 110px;
    padding: 10px;
    background: white;
  }

  &-home-link {
    padding-right: 10px;
    padding-left: 10px;
    word-break: break-all;
  }

  &-btn {
    color: #63CEB8;
    border: #63CEB8 solid 1px;
    margin: 10px 10px 20px 10px;

    &:hover, &:active:hover {
      color: #FFFFFF;
      background: #63CEB8;
      border: #63CEB8 solid 1px;
    }

    &:active, &:focus, &:active:focus {
      color: #63CEB8;
      background: #FFFFFF;
      border: #63CEB8 solid 1px;
    }
  }

  &-withdraw-list {
    list-style: none;
    margin-top: 15px;
    padding: 0;
    counter-reset: withdraw-counter;
  }

  &-withdraw-list li {
    counter-increment: withdraw-counter;
    padding-bottom: 15px;
  }

  &-withdraw-list li::before {
    content: counter(withdraw-counter);
    color: #7D9FC4;
    padding: 3px 7px;
    margin-right: 8px;
    border-radius: 50%;
    border: 1px solid #7D9FC4;
  }

  &-dialog{
    width: 800px;
  }

}
