@import "bootstrap";

.navbar-toggle,
.breadcrumb,
.dropdown-menu,
.nav-tabs > li > a,
.modal-content,
.panel,
.panel-heading,
.panel-footer,
.panel-group .panel,
.form-control,
.input-group-addon,
.alert,
.btn,
.navbar-net,
.select2-container-multi .select2-choices .select2-search-choice,
.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--multiple .select2-selection__choice,
.fc-event,
.fc-state-default.fc-corner-left,
.fc-state-default.fc-corner-right,
.datetimepicker td,
.datetimepicker th,
.datetimepicker table tr td span,
.ui-dialog,
.ui-dialog-titlebar {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}
#container {
    position: relative;
    min-height: 100%;
    overflow: hidden;
}
a, a:focus {
    color: #0099CC;
}
.display-none {
    display: none;
}
.overflow-hidden {
    overflow: hidden;
}
.position-relative {
    position: relative;
}
a, a:focus {
    outline: none;
}
.clear {
    clear: both;
}

label {
    font-weight: 600;
    font-size: 13px;
}

/* margin & padding */
.padding-reset {
    padding: 0 !important;
}
.padding-left-0 {
    padding-left: 0 !important;
}
.padding-right-0 {
    padding-right: 0 !important;
}
.margin-reset {
    margin: 0 !important;
}
@for $i from 0 through 12 {
    $px: 5 * $i;
    .margin-bottom-#{$px} {
        margin-bottom: 0px + $px !important;
    }
    .margin-top-#{$px} {
        margin-top: 0px + $px !important;
    }
    .padding-bottom-#{$px} {
        padding-bottom: 0px + $px !important;
    }
    .padding-top-#{$px} {
        padding-top: 0px + $px !important;
    }
}

/* border */
.border-left {
    border-left: 1px solid #DBDBDB;
}
.border-right {
    border-right: 1px solid #DBDBDB;
}

/* H */
h1, h2, h3, h4, h5, h6 {
    font-family: "Open Sans", sans-serif;
    margin-top: 0;
}

/* panel */
.panel {
    position: relative;
}
.panel-heading {
    font-size: 20px;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-weight: 400;
}

/* panel white */
.panel-white {
    border: #DBDBDB 1px solid;
    border-width: 5px 1px 1px;
    -moz-box-shadow: 0 0 5px #E5E5E5;
    -webkit-box-shadow: 0 0 5px #E5E5E5;
    box-shadow: 0 0 5px #E5E5E5;
}
.panel-white .panel-heading {
    background-color: #fff;
    border-bottom-color: #fff;
    padding: 6px 15px 10px;
}
.panel-white .panel-footer,
.modal-footer {
    background: #FCFCFC;
    border-top: 1px solid #EFEFEF;
}
.panel-white .border-bottom {
    border-bottom-color: #DBDBDB;
}

/* breadcrumb */
.breadcrumb {
    padding: 5px 15px;
    margin: 0 0 25px;
    background: #fff;
    border: #DBDBDB 1px solid;
    color: #5B5B5B;
    font-size: 12px;
    font-weight: 700;
}

/* loading */
#loading,
.app-loading {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background: rgba(0, 153, 204, .8);
    color: #fff;
    padding: 12px 15px;
    border: #E7EAEC 1px solid;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
div.dataTables_processing {
    border: none !important;
    background: none !important;
    left: auto !important;
    top: auto !important;
    width: 100% !important;
}

/* fa */
.fa-top {
    position: relative;
    padding-top: 50px;
    margin-top: 55px;
}
.fa-top > .fa-stack {
    height: 6em;
    line-height: 6em;
    position: absolute;
    top: -65px;
    left: 0px;
    width: 100%;
    text-align: center;
}
.fa-top > .fa-stack .fa-circle {
    font-size: 6em;
}
.fa-top > .fa-stack .fa-inverse {
    font-size: 2em;
}

/* text */
.text-success {
    color: #5CB85C;
}

/* font-size */
.font-size-12 {
    font-size: 12px;
}
.font-size-13 {
    font-size: 13px;
}

/* form */
label.required:after {
    content: "*";
    color: #F00;
    margin-right: -10px;
    margin-left: 3px;
    float: right;
}
span.required {
    color: #F00;
}
.form-control[readonly] {
    background-color: #F7F7F7;
}

/* btn */
.btn-onoff {
    background-color: #EEEEEE;
    margin-right: 5px;
}
.btn-onoff.active {
    background-color: #5CB85C;
    color: #fff; 
}
.btn-onoff.onoff-primary.active {
    background-color: #286090;
}

.btn-circle {
    text-align: center;
    line-height: 1.428571429;
    border-radius: 50% !important;
}

.btn i {
    margin-right: 5px;
}
.btn i.margin-0 {
    margin: 0;
}

.bg-azure,
.btn.btn-azure {
    color: #FFF;
    text-shadow: none;
    background-color: #02AFF3 !important;
}
.btn.btn-azure:hover,
.btn.btn-azure:focus {
    background-color: #0186b8;
}

.bg-turq,
.btn.btn-turq {
    color: #FFF;
    text-shadow: none;
    background-color: #03B8AF;
}
.btn.btn-turq:hover,
.btn.btn-turq:focus {
    background-color: #028680;
}

.bg-dark,
.btn.btn-dark {
    color: #FFF;
    text-shadow: none;
    background-color: #182129 !important;
}
.btn.btn-dark:hover,
.btn.btn-dark:focus {
    background-color: #334657;
}
.bg-yellow,
.btn.btn-yellow {
    color: #555555;
    text-shadow: none;
    background-color: #FFE100 !important;
}
.btn.btn-yellow:hover,
.btn.btn-yellow:focus {
    background-color: #FFCC00;
}
.bg-purple,
.btn.btn-purple {
    color: #ffffff;
    text-shadow: none;
    background-color: #852B99 !important;
}
.btn.btn-purple:hover,
.btn.btn-purple:focus {
    background-color: #652174;
}

/* max-width */
.max-width-750 {
    max-width: 750px;
    margin: 0 auto;
}
.max-width-700 {
    max-width: 700px;
    margin: 0 auto;
}
.max-width-300 {
    max-width: 300px;
}
.max-width-250 {
    max-width: 250px;
}
.max-width-200 {
    max-width: 200px;
}