/*
 * Plugin: Select2
 * ---------------
 */
.select2-container--default.select2-container--focus,
.select2-selection.select2-container--focus,
.select2-container--default:focus,
.select2-selection:focus,
.select2-container--default:active,
.select2-selection:active {
    outline: none;
}
.select2-container--default .select2-selection--single,
.select2-selection .select2-selection--single {
    border: 1px solid #d2d6de;
    border-radius: 0;
    padding: 6px 12px;
    height: 34px;
}
.select2-container--default.select2-container--open {
    border-color: #3c8dbc;
}
.select2-dropdown {
    border: 1px solid #d2d6de;
    border-radius: 0;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #3c8dbc;
    color: white;
}
.select2-results__option {
    padding: 6px 12px;
    user-select: none;
    -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0;
    padding-right: 0;
    height: auto;
    margin-top: -4px;
}
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 6px;
    padding-left: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 28px;
    right: 3px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    margin-top: 0;
}
.select2-dropdown .select2-search__field,
.select2-search--inline .select2-search__field {
    border: 1px solid #d2d6de;
}
.select2-dropdown .select2-search__field:focus,
.select2-search--inline .select2-search__field:focus {
    outline: none;
}
.select2-container--default .select2-results__option[aria-disabled=true] {
    color: #999;
}
.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #ddd;
}
.select2-container--default .select2-results__option[aria-selected=true],
.select2-container--default .select2-results__option[aria-selected=true]:hover {
    color: #444;
}
.select2-container--default .select2-selection--multiple {
    border: 1px solid #d2d6de;
    border-radius: 0;
}
.select2-container--default .select2-selection--multiple:focus {
    border-color: #3c8dbc;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #d2d6de;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #3c8dbc;
    border-color: #367fa9;
    padding: 1px 5px;
    color: #fff;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    margin-right: 5px;
    color: rgba(255, 255, 255, 0.7);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #fff;
}
.select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 10px;
}
.select2-container .select2-selection--multiple {
    min-height: 34px;
}

html, body {
    background: #F5F5F5;
    min-height: 100%;
    color: #131410;
}
body {
    overflow-x: hidden;
    overflow-y: auto;
}
.container {
    width: 100%;
}
.options-right {
    position: absolute;
    z-index: 1;
    top: 45px;
    right: 0;
}
.options-right .dropdown {
    display: inline-block;
}
.tab-content .options-right {
    top: -35px;
}

button:hover,
button:focus,
button:active {
    outline: none;
}

/* top */
header {
    background: #fff;
    border-bottom: 1px solid #E2E9E6;
    height: 75px;
}
#logo,
#logo-mobile {
    background: #0099CC;
    padding: 10px 15px;
}
#logo-mobile  img {
    max-height: 30px;
}
#logo {
    margin-left: -15px;
    height: 75px;
    width: 250px;
    border-right: #333 1px solid;
    border-bottom: #F5F5F5 1px solid;
}
#logo img {
    position: relative;
    max-height: 60px;
}

.user-nav .btn-link {
    color: #000;
    font-weight: 600;
}
.user-nav img {
    margin-right: 5px;
}
.btn .caret {
    margin-left: 3px;
    margin-bottom: 2px;
}
.open > .dropdown-menu {
    width: 100%;
}
.dropdown-toggle:hover,
.dropdown-toggle:focus,
.dropdown-toggle:active {
    text-decoration: none;
}
.dropdown-menu {
    padding: 0px;
    margin-top: -4px;
    border: none;
    box-shadow: 0 2px 3px #a9a9a9;
}
.dropdown-menu > li > a {
    font-weight: 500;
    padding: 8px 14px;
    border-top: 1px solid #EFEFEF;
}
.dropdown-menu > li > a > .fa {
    margin-right: 5px;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    text-decoration: none;
    color: #42a5f5;
    background-color: #F5F5F5;
}

/* menu */
#sidebar-menu {
    position: absolute;
    z-index: 1;
    left: 0;
    background: #0099CC;
    width: 250px;
    min-height: 100%;
    border-top: #009fd4 1px solid;
    border-right: #333 1px solid;
}
#sidebar-menu .container-fluid,
#sidebar-menu .navbar-collapse,
#sidebar-menu .navbar,
#sidebar-menu ul,
#sidebar-menu li {
    margin: 0;
    padding: 0;
    min-height: 1px;
    border: none;
}
#sidebar-menu ul,
#sidebar-menu li {
    list-style: none;
}
#sidebar-menu .nav a {
    font: 700 14px 'Open Sans', sans-serif;
    color: #fff;
    padding: 14px 10px 14px 15px;
    display: block;
}
#sidebar-menu .nav a .fa {
    margin-right: 5px;
}
#sidebar-menu .nav a:hover .fa {
    text-decoration: none;
}
#sidebar-menu .nav > li.active {
    border-left: 3px solid #fff;
}
#sidebar-menu .nav > li.dropdown,
#sidebar-menu .nav > li.active.open-toggle {
    padding-bottom: 5px;
}
#sidebar-menu .nav > li.active,
#sidebar-menu .nav li.active a,
#sidebar-menu .nav > li > a:hover {
    background: #0079ab;
}
#sidebar-menu .nav > li > a:active,
#sidebar-menu .nav > li > a:focus {
    background: none;
}
#sidebar-menu .nav > li > ul {
    display: none;
    background: #00A8E0;
}
#sidebar-menu .nav > li.active > ul {
    display: block;
}
#sidebar-menu .nav > li > ul > li > a {
    padding: 7px 5px 7px 13px;
    font-size: 13px;
}
#sidebar-menu .nav > li > ul > li.active > a {
    text-decoration: underline;
}
#sidebar-menu .arrow {
    float: right;
    padding-top: 2px;
    position: static !important;
}
#sidebar-menu .fa.arrow:before {
    content: "\f104";
}
#sidebar-menu .open-toggle > a > .fa.arrow:before {
    content: "\f107";
}
#sidebar-menu .badge {
    float: right;
    background: rgba(255, 255, 255, 0.7);
    color: #0079AB;
    font-size: 11px;
    padding: 2px 5px;
    margin-top: 2px;
}

.navbar-toggle,
.navbar-toggle-440 {
    float: left;
    margin: 0 0 0 -15px;
    width: 120px;
    height: 75px;
    background: #002749;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
}
.navbar-toggle-440 {
    display: none !important;
}

/* footer */
footer {
    width: 100%;
    bottom: 0;
    color: #fff;
    background: #0099CC;
    padding: 15px 0 10px;
    font-size: 12px;
}
footer img {
    position: relative;
    margin-left: 4px;
    bottom: 2px;
}

/* error */
.error {
    max-width: 500px;
    margin: 0 auto;
}
.error .fa-exclamation-triangle {
    font-size: 100px;
    margin-right: 30px;
}
.error h3 {
    margin: 22px 0 5px;
}

/* content */
#content {
    margin-left: 250px;
}
#content > .container-fluid {
    padding: 35px 0 0 5px;
    position: relative;
    margin: 0 auto;
}
#content .title {
    font: 300 35px 'Open Sans', sans-serif;
    margin-bottom: 15px;
}
#content .title > small {
    font-size: 15px;
}
#content .title > small:before {
    content: "/ ";
    padding: 0 5px;
    color: #CCC;
}
#content h3.title {
    font-size: 24px;
    font-weight: 400;
}

/* form */
form h6 {
    font-size: 16px;
    margin-bottom: 15px;
}
form h6:before {
    display: inline-block;
    font-family: FontAwesome;
    content: "\f101";
    padding-right: 10px; 
    color: #939393;
}
.dropzone {
    background: #FAFAFA;
    padding: 15px;
    border: #DBDBDB 1px solid;
}
button.btn.form-control {
    width: auto;
    margin-top: 24px;
}
.file-wrapper .btn {
    overflow: hidden;
}
.file-wrapper input[type=file] {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 15px;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    background-color: transparent;
    color: transparent;
    cursor: pointer;
    min-height: 34px;
    max-width: 84px;
}

/* tab-content */
.tab-content {
    background: #fff;
    padding: 20px 15px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent #DDD #DDD;
    position: relative;
}
.tab-content.tab-panel {
    background: none;
    border: none;
    padding-left: 0;
    padding-right: 0;
}

/* datetimepicker */
.datetimepicker {
    margin-left: 7px;
}
.datetimepicker .active {
    background-color: #0099CC !important;
    background-image: none !important;
    filter: none !important;
}
.datetimepicker table td,
.datetimepicker table th {
    padding: 8px !important;
}
.datetimepicker table td {
    font-weight: 400 !important;
    font-family: 'Open Sans' !important;
}
.datetimepicker .datetimepicker-hours span {
    height: 30px !important;
    line-height: 30px !important;
}

/* table */
.dt-responsive {
    width: 100%;
}
.dt-responsive th,
.dt-responsive td {
    white-space: nowrap;
}
table .fa-button {
    font-size: 18px;
    color: #727272;
}
table .fa-button.warning,
table .fa-button.warning:hover {
    color: #E5AC00;
}
table .fa-button.success,
table .fa-button.success:hover {
    color: #01B204;
}
table .fa-button.danger,
table .fa-button.danger:hover {
    color: #CE1C1C;
}
table .options .fa,
table .fa-university {
    margin: 0 4px;
    color: #727272;
    font-size: 18px;
}
table small {
    color: #A5A5A5;
}
table .btn-info small {
    color: #fff;
}


.align-middle,
.table.align-middle > tbody > tr > td,
.table > tbody > tr > td.align-middle {
    vertical-align: middle;
}
.align-top,
.table.align-top > tbody > tr > td,
.table > tbody > tr > td.align-top {
    vertical-align: top;
}
.table.dataTable thead > tr > th.checkbox_all {
    padding-right: 8px;
}
.dt-responsive td.wrap {
    white-space: normal;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child {
    padding-left: 5px;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child::before,
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child::before {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-family: FontAwesome;
    content: "\f055";
    background: none;
    color: #31B131;
    font-size: 18px;
    height: auto;
    width: auto;
    display: inline-block;
    position: relative;
    top: 1px;
    left: auto;
    margin-right: 10px;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child::before {
    content: "\f056";
    color: #D33333;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.child td::before {
    display: none;
}
table.dataTable thead > tr > th.sorting_disabled {
    padding-right: 8px;
}

/* dl */
.dl-horizontal dt {
    margin-bottom: 6px;
}
.dl-horizontal.px240 dt {
    width: 240px;
}
.dl-horizontal.px240 dd {
    margin-left: 250px;
}

.nav-tabs > li > a {
    border-top: transparent 2px solid;
}
.nav-tabs > li > a:hover {
    color: #23527c;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
    border-top: #0099CC 2px solid;
}

/* messages */
.messages-body {
    max-height: 400px;
    overflow-y: auto;
}
.chats {
    margin:0;
    padding: 0;
}
.chats > li {
    padding: 5px 0;
    margin: 10px auto;
    font-size: 14px;
    line-height: 20px;
    background: #FAFAFA;
    overflow: hidden;
}
.chats .avatar {
    float: left;
    margin-right: 10px;
}
.chats .message {
    margin-left: 60px;
}
.chats .datetime, 
.chats .view_date {
    float: right;
    font-size: 12px;
    color: #A5A5A5;
}
.chats .name {
    font-weight: bold;
}
.chats .body {
    display: block;
    font-size: 13px;
}
.chats .price {
    display: block;
    font-size: 16px;
    padding: 5px 0;
    color: #0079AB;
    font-weight: bold;
}
.chats .attachment {
    padding-top: 5px;
    font-size: 12px;
}
.chats .attachment a {
    text-decoration: underline;
}
.chats .view_date {
    display: block;    
}

/* note-editable */
.note-editable h1, .note-editable h2 {
    margin-top: 10px;
    margin-bottom: 25px;
}
.note-editable h3, .note-editable h4, .note-editable h5, .note-editable h6 {
    margin-top: 10px;
    margin-bottom: 15px;
}

/* help */
.help-wrapper button {
    outline: none;
}
#top-help,
.tab-content .help-wrapper {
    position: absolute;
    top: -1px;
    left: 0;
}
#top-help button {
    border-top: none;
}
.tab-content .help-wrapper {
    left: auto;
    right: 0;
}
.tab-content .help-wrapper button {
    border-top: none;
    border-right: none;
}

/* payment */
.payment {
    margin: 20px auto 30px;
    text-align: center;
}
.payment .btn {
    white-space: normal;
}

/* bootstrap-rating */
.rating-symbol {
    font-size: 16px;
    margin: 0;
    padding: 0 2px;
}
.rating-symbol .fa-star {
    color: #FFCC1C;
}
.symbol {
    display: inline-block;
    border-radius: 50%;
    border: 5px double white;
    width: 30px;
    height: 30px;
}
.symbol-empty {
    background-color: #ccc;
}
.symbol-filled {
    background-color: black;
}
#addReview .rating-symbol {
    cursor: pointer;
    font-size: 20px;
}

blockquote {
    border-left-color: #AAAAAA;
}

/* tile */
.tile {
    text-align: center;
    padding: 20px;
    font: 300 20px 'Open Sans', sans-serif; 
    position:relative;       
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.tile:before, .tile:after {
    content:"";
    position:absolute; 
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:0;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
} 
.tile:after {
    right:10px; 
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg); 
    -moz-transform:skew(8deg) rotate(3deg);     
    -ms-transform:skew(8deg) rotate(3deg);     
    -o-transform:skew(8deg) rotate(3deg); 
    transform:skew(8deg) rotate(3deg);
}
.tile i {
    font-size: 50px;
    margin-bottom: 10px;
    opacity: 0.8;
    filter: alpha(opacity=80);
}
.tile-href:hover {
    text-decoration: none;
}
.tile-href:hover .tile {
    background-color: #0099CC !important;
    color: #fff !important;
}

.files li {
    margin-bottom: 12px;
}
.files li button {
    margin-left: 10px;
    position: relative;
    bottom: 1px;
}

.editor {
    min-height: 500px;
}
.tooltip-inner {
    max-width: 300px;
}

.campaign-contact,
.offer-contact {
    position: absolute;
    right: 0;
}
.campaign-contact {
    top: 10px;
    right: 15px;
}

.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
}

.timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #eeeeee;
    left: 50%;
    margin-left: -1.5px;
}

.timeline > li {
    margin-bottom: 20px;
    position: relative;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li > .timeline-panel {
    width: 46%;
    float: left;
    background: #fff;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    padding: 20px;
    position: relative;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}

.timeline > li > .timeline-panel:before {
    position: absolute;
    top: 26px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid #ccc;
    border-right: 0 solid #ccc;
    border-bottom: 15px solid transparent;
    content: " ";
}

.timeline > li > .timeline-panel:after {
    position: absolute;
    top: 27px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #fff;
    border-right: 0 solid #fff;
    border-bottom: 14px solid transparent;
    content: " ";
}

.timeline > li > .timeline-badge {
    color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -25px;
    background-color: #999999;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}

.timeline > li.timeline-inverted > .timeline-panel {
    float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.timeline-badge.primary {
    background-color: #2e6da4 !important;
}

.timeline-badge.success {
    background-color: #3f903f !important;
}

.timeline-badge.warning {
    background-color: #f0ad4e !important;
}

.timeline-badge.danger {
    background-color: #d9534f !important;
}

.timeline-badge.info {
    background-color: #5bc0de !important;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}

.timeline-body > p + p {
    margin-top: 5px;
}

@media (max-width: 767px) {
    ul.timeline:before {
        left: 40px;
    }

    ul.timeline > li > .timeline-panel {
        width: calc(100% - 90px);
        width: -moz-calc(100% - 90px);
        width: -webkit-calc(100% - 90px);
    }

    ul.timeline > li > .timeline-badge {
        left: 15px;
        margin-left: 0;
        top: 16px;
    }

    ul.timeline > li > .timeline-panel {
        float: right;
    }

    ul.timeline > li > .timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
    }

    ul.timeline > li > .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
    }
}

/* select2 */
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: #0099CC;
    border-color: #0099CC;
    color: #fff;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff;
    margin-right: 5px;
}

.input-group-addon .checkbox {
    margin: 0;   
}
.input-group-addon .input-group-addon input[type="radio"],
.input-group-addon .input-group-addon input[type="checkbox"] {
    margin-top: 1px;
}
.input-group-addon .radio label,
.input-group-addon .checkbox label {
    padding-top: 2px;  
}
.form-horizontal .input-group-addon .checkbox {
    padding-top: 0;
    min-height: 1px;
}
.has-feedback .form-control {
    padding-right: 6px;
}
.form-horizontal .has-feedback .form-control-feedback {
    right: -22px;
}
.nav-tabs li a .badge {
    font-weight: normal;
    vertical-align: baseline;
}

.textarea-tinymce {
    min-height: 510px;
}

.v-align{
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.disabled-content {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.img-avatar{
    width: 45px;
    height: 45px;
}

@import "pages/campaign";
@import "pages/pp";