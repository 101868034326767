html#logout {
    background: #0099CC;
    height: 100%;
}
#logout body {
    height: 100%;
    background: none;
}
#logout .container {
    max-width: 1000px;
    padding-top: 50px;
}
#logout .error .panel-heading {
    font-size: 40px;
    font-weight: 300;
}
#logout .error .fa-exclamation-triangle {
    font-size: 62px;
    margin-right: 25px;
}

/* footer */
#logout footer {
    position: absolute;
    z-index: 1;
    width: 100%;
    bottom: 0;
    color: #fff;
    background: rgba(0, 0, 0, .5);
    padding: 10px 0 5px;
    font-size: 12px;
}
#logout footer > .container {
    padding-top: 0;
}
#logout footer img {
    position: relative;
    margin-left: 4px;
    bottom: 2px;
}

/* form */
#box-register {
    max-width: 600px;
    margin: 0 auto;
}